<script setup>

</script>

<template>
  <router-view/>
</template>

<style>
body {
  margin: 0;
  height: 100%;
}
</style>

<script>

export default {
  methods: {

  },
  mounted() {

  }
}

</script>
